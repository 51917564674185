@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-text-gradient {
  background: linear-gradient(95deg, #00004B 0%, #FFD200 98.32%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hover-button {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  transition: all 0.3s ease; /* Smooth transition */
  /* Add other styles like padding, font, border, etc., as needed */
}

.hover-button:hover {
  color: #000; /* Change color on hover if needed */
}

.hover-button:hover::before {
  margin-right: 16px; /* Increase space when hovered */
  content: '←';
  transition: margin-right 0.3s ease;
}

.gradient-border-up::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px; /* Adjust the width of the border here */
  background: linear-gradient(to bottom, #00004B, #FFD200); /* Customize your gradient colors here */
  border-radius: 8px;
}
.gradient-border-down::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px; /* Adjust the width of the border here */
  background: linear-gradient(to bottom, #FFD200, #00004B); /* Customize your gradient colors here */
  border-radius: 8px;
}
